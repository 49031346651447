"use strict";

/**
* all.hero Module
*
* Description
*/
angular.module('all.theme', ['ngTouch', 'ngAnimate', 'uiGmapgoogle-maps', 'mm.foundation'])

.config(['uiGmapGoogleMapApiProvider', function(uiGmapGoogleMapApiProvider) {
  uiGmapGoogleMapApiProvider.configure({
      key: 'AIzaSyCrHcNCGVsn8Hfgc8hXOB39JmCPKUKuD5k',
      v: '3.17',
      libraries: 'weather,geometry,visualization'
  });
}])

.directive('themeGallery', function(){
  // Runs during compile
  return {
    name: 'themeGallery',
    scope: {
      items: '='
    },
    controller: ['$scope', '$modal', function($scope, $modal) {
      var categories = {all: []};

      $scope.category = 'all';

      // Group into cateogires
      angular.forEach($scope.items, function (item) {

        categories.all.push(item);

        angular.forEach(item.categories, function (cat) {
          categories[cat] = categories[cat] || [];
          categories[cat].push(item);
        });
      });

      $scope.ceil = function (num) {
        return Math.ceil(num);
      };

      /**
       * Returns an array of category names
       * @return {Array}
       */
      $scope.getCategories = function () {
        return Object.keys(categories);
      };

      $scope.getItems = function () {
        return categories[$scope.category];
      };

      /**
       * Opens the image given in category at index
       * @param  {String}
       * @param  {Integer}
       * @return {Void}
       */
      $scope.open = function (index) {
        $modal.open({
          templateUrl: '/app/themes/all-theme/assets/jsTemplates/theme-gallery-item.html',
          controller: ['$scope', function ($$scope) {
            $$scope.item = categories[$scope.category][index];

            $$scope.$$postDigest (function () {
              picturefill({ reevaluate: true, elements: jQuery('#gallery_image') });
            });

            $$scope.prev = function () {
              index--;
              if(index < 0) {
                index = categories[$scope.category].length -1;
              }
              $$scope.item = categories[$scope.category][index];

              $$scope.$$postDigest (function () {
                picturefill({ reevaluate: true, elements: jQuery('#gallery_image') });
              });
            };

            $$scope.next = function () {
              index++;
              if(index >= categories[$scope.category].length) {
                index = 0;
              }
              $$scope.item = categories[$scope.category][index];

              $$scope.$$postDigest (function () {
                picturefill({ reevaluate: true, elements: jQuery('#gallery_image') });
              });
            };
          }]
        });
      };
    }],
    restrict: 'E',
    templateUrl: '/app/themes/all-theme/assets/jsTemplates/theme-gallery.html'
  };
});
